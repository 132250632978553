<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div v-if="(id > 0)" class="col-sm-6">
                        <h1>{{ $t('general.Mensaje Predefinido') }}</h1>
                    </div>
                    <div v-else class="col-sm-6">
                        <h1>{{ $t('general.Crear Mensajes Predefinidos') }}</h1>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div v-if="(id > 0)" class="col-md-2">
                    <!-- Datos correos -->
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{ mensaje_predifinido.titulo_texto }}</h3>
                        </div>
                    </div>
                    <!--<div class="callout callout-info">
                  <h5>Última modificación</h5>
                    <p>15/12/2021 15:35h por soporte2</p>
                    </div>-->
                </div>
                <div class="col-md-6">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_estado_pendiente" data-toggle="tab">{{
                                    $t('general.datos') }}</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_estado_pendiente">
                            <mensaje-predefinido-datos :id="id" :key="id" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import MensajePredefinidoDatos from './MensajePredefinidoDatos.vue'
export default {
    components: {
        'mensaje-predefinido-datos': MensajePredefinidoDatos,
    },
    props: ['id'],
    data() {
        return {
            mensaje_predifinido: [],
        }
    },
    methods: {
        async obtenerMensajePredefinido(id) {
            const api = new PwgsApi;
            const datos = await api.get('mensajes-predefinidos/' + id); 
            this.mensaje_predifinido = datos.datos;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/mensajespredefinidos/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.mensaje_predifinido.titulo_texto;
                    this.$store.getters.ventanas[i].alt = "Mensaje Predefinido: " + this.mensaje_predifinido.titulo_texto;
                }
            }
            /**/
        },

        cambios() {
            this.obtenerMensajePredefinido(this.id);
        },
        recargar() {
            this.obtenerMensajePredefinido(this.id);
        },
    },
    mounted() {
        this.obtenerMensajePredefinido(this.id);
    },
    watch: {
        id() {
            this.obtenerMensajePredefinido(this.id);
        },
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;
            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == '/mensajespredefinidos/' + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.mensaje_predifinido.titulo_texto;
                    this.$store.getters.ventanas[i].alt = "Mensaje Predefinido: " + this.mensaje_predifinido.titulo_texto;
                }
            }
            /**/
        }
    }
}
</script>
