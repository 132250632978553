<template>
    <seccion-datos :titulo="estadopendientedatos" @cambios="guardar()">

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="nombre_completo">{{ $t('general.nombre') }}</label>
                    <input type="text" class="form-control nombre_completo dato" id="titulo_texto" placeholder="" v-model="titulo_texto">
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="nombre_completo">Tipo</label>
                    <select class="form-control" v-model="tipo_mensaje" name="tipo_mensaje">
                        <option value="sms">SMS</option>
                        <option value="email">E-Mail</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="nombre_completo">Texto</label>
                    <textarea class="form-control dato" name="texto" placeholder="" rows="15" v-model="texto_mensaje"></textarea>
                </div>
            </div>
        </div>   

    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: {
        id: Number,
        
    },
    data() {
        return {
            estadopendientedatos: "Datos", 
            mensaje_predefinido: Object,
            titulo_texto: '',
            tipo_mensaje: '',
            texto_mensaje: ''
        }
    },
    methods: {
        async cargardatos() {
           
            this.$store.dispatch('cambiarCargando', true);
            const api = new PwgsApi;
            const datos = await api.get('mensajes-predefinidos/' + this.id);
            this.mensaje_predefinido = datos.datos;
            this.$store.dispatch('cambiarCargando', false);

            this.titulo_texto = this.mensaje_predefinido.titulo_texto;
            this.tipo_mensaje = this.mensaje_predefinido.tipo_mensaje;
            this.texto_mensaje = this.mensaje_predefinido.texto;
            
          
        },
        async guardar() {
            const api = new PwgsApi;
            const datos = { titulo_texto : this.titulo_texto, tipo_mensaje: this.tipo_mensaje, texto: this.texto_mensaje };
            if (this.id > 0) {
                try {
                    this.$store.dispatch('cambiarGuardando', true);
                    await api.put('mensajes-predefinidos/' + this.id, datos);
                    this.$store.dispatch('cambiarGuardando', false);
                    this.$parent.obtenerMensajePredefinido(this.id);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                try {
                    let res;
                    res = await api.post('mensajes-predefinidos/', datos);
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    this.$router.push('/mensajes-predefinidos/' + res.id);
                    alert("guardado");
                }
                  catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
        comprobar() { }
    },
    mounted() {
        this.cargardatos();
    }
}
</script>